/** @format */

import React from 'react';
import './index.scss';
import { RouteComponentProps } from 'react-router-dom';
import UploadModal from './component/uploadModal';
import LoginModal from './component/login';
import { getUrlParams } from '@utils/common';
interface IState {
  show: boolean;
  showLogin: boolean;
}

interface IProps {
  match?: any;
}

type PropsType = RouteComponentProps & IProps;

class UploadGroupList extends React.Component<PropsType, IState> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      show: false,
      showLogin: true,
    };
  }

  handleCancel = () => {
    this.setState({
      show: false,
    });
  };
  closeLogin = () => {
    this.setState({
      showLogin: false,
    });
  };
  render() {
    let { show, showLogin } = this.state;
    return (
      <div className='upload-list'>
        {showLogin && (
          <LoginModal
            closeLogin={this.closeLogin}
            mobile={getUrlParams('mobile')}
          />
        )}
        <div className='banner'>
          <div className='logo'></div>
          <div className='company-name'>寰宇关爱</div>
        </div>
        <div className='step'></div>
        <div
          className='upload'
          onClick={() => {
            this.setState({
              show: true,
            });
          }}
        >
          上传人员名单
        </div>
        <div className='download'>
          <a href='/团险名单模板.xlsx' target='_blank' download=''>
            下载模板
          </a>
        </div>
        <div className='upload-tips'>
          <div className='title'>导入订单注意事项：</div>
          <div className='tips-item'>
            <div className='tips-icon'>* </div>
            <div className='tips-text'>
              上传名单注意事项：* 只能上传.xls, .xlsx 文件，且不能超过10M
            </div>
          </div>
          <div className='tips-item'>
            <div className='tips-icon'>* </div>
            <div className='tips-text'>
              内容必须包含姓名、证件类型、证件号码、性别、年龄、出生日期、职业
            </div>
          </div>
          <div className='tips-item'>
            <div className='tips-icon'>* </div>
            <div className='tips-text'>团险被保人至少为3人</div>
          </div>
          <div className='tips-item'>
            <div className='tips-icon'>* </div>
            <div className='tips-text'>
              被保人55周岁时，需补充健康告知，具体核保结果以保司审核为准
            </div>
          </div>
        </div>
        {show && (
          <UploadModal
            id={this.props.match.params.id}
            handleCancel={this.handleCancel}
          />
        )}
      </div>
    );
  }
}

export default UploadGroupList;
