import React, { Suspense } from 'react';
// import history from '@utils/history';
// react-router-dom 5.0不适用history了
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import SetPrice from 'page/setPrice';
import Credit from 'page/credit'
import UploadGroupList from 'page/uploadGroupList';
import InsuranceCompany from '../exportSettlement/insuranceCompany'


interface IProps {}
interface IState {}

class App extends React.Component<IProps, IState> {
    render () {
        return (
            <Router>
                <Suspense fallback={null}>
                    <Switch>
                        {/* <Route 
                            path="/login" 
                            component={Login}
                        ></Route>
                        <Redirect to="/login" /> */}
                        <Route path="/credit"  component={Credit}></Route>
                        <Route path="/setPrice/:id" component={SetPrice}></Route>
                        <Route path="/uploadGroupList/:id" component={UploadGroupList}></Route>
                        <Route path="/export/insuranceCompany" component={InsuranceCompany}></Route>
                    </Switch>
                </Suspense>
            </Router>
        )
    }
}

export default App