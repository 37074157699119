import { action, observable } from 'mobx';
import * as loginApi from '@api/login';
import { LoginFrom } from './interface';

class Store {
    @observable token: string = '';

    @action setToken = (token: string) => {
        this.token = token
    }

    @action login = (params: LoginFrom) => {
        return loginApi.login(params)
    }
}

export default new Store();