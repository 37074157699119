import React, { forwardRef } from "react";
import { InputNumber, Form } from "antd";
import './index.scss';

interface Item {
    id: string;
    costPrice: number;
    serviceTime: number;
    happenFactor: number;
    name: string;
}

const EditTable = forwardRef((props: any, ref:any) => {
    const [form] = Form.useForm();
    let { serviceItemsList } = props
   
    return (
        <div className="edit-table">
            <div className="table-header">
                <div>服务项名称</div>
                <div>成本价（元）</div>
                <div>服务时长（天）</div>
                <div>单人最大使用次数</div>
                <div>服务发生率（%）</div>
            </div>
            <Form  form={form} component={false} ref={ref}>
                <div className="form-table">
                    {serviceItemsList.map((item: Item, index: string | number | undefined) => (
                        <div key={index} className="table-item">
                            <div className="form-item">{item.name}</div>
                            <div className="form-item">
                                <Form.Item
                                    name={`costPrice-${index}`}
                                    rules={[{ required: true, message: '成本价必填' }]}
                                >
                                    <InputNumber placeholder={'请输入'} style={{width: '114px'}} min={0} step={0.01}/>
                                </Form.Item>
                            </div>
                            <div className="form-item">
                                <Form.Item
                                    name={`serviceTime-${index}`}
                                    rules={[{ required: true, message: '服务时长必填' }]}
                                >
                                    <InputNumber placeholder={'请输入'} style={{width: '114px'}} min={0}/>
                                </Form.Item>
                            </div>
                            <div className="form-item">
                                <Form.Item
                                    name={`singleMax-${index}`}
                                    rules={[{ required: true, message: '单人最大使用次数' }]}
                                >
                                    <InputNumber placeholder={'请输入'} style={{width: '114px'}} min={1} max={999}/>
                                </Form.Item>
                            </div>
                            <div className="form-item">
                                <Form.Item
                                    name={`happenFactor-${index}`}
                                    rules={[{ required: true, message: '服务发生率必填' }]}
                                >
                                    <InputNumber placeholder={'请输入'} style={{width: '114px'}} min={0} max={100} step={0.01}/>
                                </Form.Item>
                            </div>
                        </div>
                    ))}
                </div>
            </Form>
        </div>
    );
});

export default EditTable;
