import request from '@utils/request';

export function uploadList(data: any) {
    return request({
        url: `/api/insurance/underwriting/importPersons`,
        method: 'post',
        data
    })
}

export function submitPersonList(data: any) {
    return request({
        url: '/api/insurance/underwriting/submitPersonList',
        method: 'post',
        data
    })
}