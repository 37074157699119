import React, {createRef} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './index.scss';
import { Form, Select, InputNumber, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import EditTable from './component/EditTable';
import { ratioConfig } from './config';
import { observer, inject} from 'mobx-react';
import { toJS } from 'mobx';
import { SetPriceStore } from '@store/interface';
import Loading from '@components/Loading';
import { Partial } from '../../types/common'

interface IProps {
    setPriceStore: SetPriceStore,
    match?: any
}

interface IState {
    activeIndex: number,
    loading: boolean,
    initialValues: {
        insuraceProId: string
    }
}

interface Init {
    id: string,
    name: string,
    insuredRiskFactor: number,
    saleArea: number,
    riskManage: number,
    manageServiceFactor: number,
    insuredCountFactor: number,
    discountFactor: number,
    saleFactor: number,
    goeCost: number,
    marketCost: number,
    profitRate: number,
    sysCost: number
}

type InitData = Partial<Init>;

type PropsType = RouteComponentProps & IProps;

const { Option } = Select;

@inject('setPriceStore')
@observer
class SetPrice extends React.Component<PropsType, IState> {
    formRef = createRef<FormInstance>();
    rateRef = createRef<FormInstance>();
    profitRef = createRef<FormInstance>();
    tableRef = createRef<any>();

    constructor(props: PropsType) {
        super(props)
        this.state = {
            activeIndex: 1,
            loading: false,
            initialValues: {
                insuraceProId: this.props.match.params.id
            }
        }
    }

    componentDidMount() {
        this.insuranceProduct();
        if(this.props.match.params.id) {
            this.getServiceItems(this.props.match.params.id)
        }
        this.getInitData();
    }

    // 获取初始化数据
    getInitData = () => {
        this.props.setPriceStore.getInitData()
    }

    // 获取保险产品list
    insuranceProduct = () => {
        this.props.setPriceStore.insuranceProduct()
    }

    // 根据id获取服务项
    getServiceItems = (id:string) => {
        this.props.setPriceStore.getServiceItems(id)
    }

    // 保费测算
    submit = () => {
        let { serviceItemsList } = toJS(this.props.setPriceStore)
        // this.tableRef.current.getFieldsValue()
        let tableRefPromise = this.tableRef.current.validateFields().then((values: any) => {
            serviceItemsList.forEach( (item,index) => {
                for(let key in values) {
                    if(key.indexOf(String(index)) > -1) {
                        item[key.substring(0, key.length - 2)] = values[key]
                    }
                }
            })
            return Promise.resolve(serviceItemsList);
        }).catch((errorInfo: any) => {
            return Promise.reject();
        });
        
        let formRefPromise = this.formRef.current && this.formRef.current.validateFields().then(values => {
            return Promise.resolve(values);
        }).catch((errorInfo: any) => {
            return Promise.reject();
        });

        Promise.all([tableRefPromise, formRefPromise]).then((postDataList: any) => {
            this.setState({
                loading: true
            })
            
            let postData:any = {
                vos: postDataList[0],
                insuraceProId: postDataList[1].insuraceProId,
                purchaseNum: postDataList[1].purchaseNum
            }
            this.props.setPriceStore.setPrice(postData).then(() => {
                setTimeout(() => {
                    this.setState({
                        loading: false
                    })
                }, 1800)
            })
        }).catch(err => {
            console.log("SetPrice -> onFill -> err", err)
        })
    };

    // tab切换
    tabChange = (activeIndex: number) => {
        this.setState({
            activeIndex
        })
    }
    // 基本配置保存
    saveInit = (type: number) => {
        let { initData } = this.props.setPriceStore
        if(type === 1) {
            let rateData = this.rateRef.current?.getFieldsValue();
            let postData:InitData = {
                id: initData.id,
                ...rateData
            } 
            this.props.setPriceStore.saveInitData(postData).then((res: any) => {
                message.success('更新成功')
                this.getInitData()
            })
        } else {
            this.profitRef.current?.validateFields().then((values: InitData) => {
                
                let postData:InitData = {
                    id: initData.id,
                    ...values
                } 
                this.props.setPriceStore.saveInitData(postData).then((res: any) => {
                    message.success('更新成功')
                    this.getInitData()
                })
            }).catch((errorInfo: any) => {
                return
            });
        }
    }

    // 保险产品改变获取服务项
    insuranceProductChange = (id: string) => {
        this.getServiceItems(id)
    }

    render () {
        let { activeIndex, loading, initialValues } = this.state
        let { insuranceProductList, serviceItemsList, finalPrice, serviceList, initData } = toJS(this.props.setPriceStore)

        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        return (
            <div className="set-price">
                <div className="header">AI参考定价</div>
                <div className="layout">
                    <div className="tab-box">
                        <div onClick={() => {
                            this.tabChange(1)
                        }} className={`tab-item ${activeIndex === 1 && 'tab-item-active'}`}>
                            报价测算
                        </div>
                        {serviceList.length > 0 && (
                            <div onClick={() => {
                                this.tabChange(2)
                            }} className={`tab-item ${activeIndex === 2 && 'tab-item-active'}`}>
                                单个服务项
                            </div>
                        )}
                        <div onClick={() => {
                            this.tabChange(3)
                        }} className={`tab-item ${activeIndex === 3 && 'tab-item-active'}`}>
                            费率基数
                        </div>
                        <div onClick={() => {
                            this.tabChange(4)
                        }} className={`tab-item ${activeIndex === 4 && 'tab-item-active'}`}>
                            期望利润
                        </div>
                    </div>
                    <div className={`calculation ${activeIndex === 1 && 'calculation-show'}`}>
                        <Form
                            {...layout}
                            ref={this.formRef}
                            name="calculator"
                            initialValues={initialValues}
                        >
                            <Form.Item
                                label="选择保险产品"
                                name="insuraceProId"
                                rules={[{ required: true, message: '保险产品必填' }]}
                            >
                                <Select
                                    placeholder="请选择保险产品"
                                    onChange={this.insuranceProductChange}
                                >
                                    {insuranceProductList.map(item => (
                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="输入服务采购量（人数）"
                                name="purchaseNum"
                                rules={[{ required: true, message: '服务采购量必填' }]}
                            >
                                <InputNumber placeholder={'请输入服务采购量'} style={{width: '100%'}} min={1} />
                            </Form.Item>
                        </Form>
                        <div>
                            <div className="item-label">设置服务项参数</div>
                            <EditTable ref={this.tableRef} serviceItemsList={serviceItemsList}/>
                        </div>
                        <div className="submit" onClick={() => {
                            this.submit()
                        }}>
                            开始测算
                        </div>
                        <div>
                            <div className="item-label">对外最低报价</div>
                            <div className="out-price">
                                <div className="ring-box">
                                    <div className="price">
                                        <span className="integer">{finalPrice.integer}</span>
                                        <span className="decimal">{finalPrice.decimal}元</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {activeIndex === 2 && (
                        <div className="calculation calculation-show" >
                            {serviceList.map((item: any,index:number) => (
                                <div key={item.id} className="service-item">
                                    <div className="service-title">{`服务项${index + 1}`}</div>
                                    <div className="item-box">
                                        <div className="title">{item.name}</div>
                                        <div className="table">
                                            <div className="table-flex">
                                                <div className="item-left">服务采购量/（人次）</div>
                                                <div className="item-right">{item.purchaseNum}</div>
                                            </div>
                                            <div className="table-flex">
                                                <div className="item-left">服务成本价/次</div>
                                                <div className="item-right">¥{item.costPrice}</div>
                                            </div>
                                            <div className="table-flex">
                                                <div className="item-left">服务时长/天</div>
                                                <div className="item-right">{item.serviceTime}</div>
                                            </div>
                                            <div className="table-flex">
                                                <div className="item-left">单人最大使用次数</div>
                                                <div className="item-right">{item.singleMax}</div>
                                            </div>
                                            <div className="table-flex">
                                                <div className="item-left">服务最大使用率</div>
                                                <div className="item-right">{item.serviceTime}%</div>
                                            </div>
                                            <div className="table-flex">
                                                <div className="item-left">服务发生率</div>
                                                <div className="item-right">{item.useMax}%</div>
                                            </div>
                                            <div className="table-flex">
                                                <div className="item-left">总服务次数</div>
                                                <div className="item-right">{item.personNum}</div>
                                            </div>
                                            <div className="table-flex">
                                                <div className="item-left">总服务成本</div>
                                                <div className="item-right">¥{item.allCost}</div>
                                            </div>
                                            <div className="table-flex">
                                                <div className="item-left">基本费率</div>
                                                <div className="item-right">¥{item.baseRate}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {activeIndex === 3 && (
                        <div className="calculation calculation-show">
                            <Form
                                {...layout}
                                ref={this.rateRef}
                                name="calculator"
                                initialValues={initData}
                            >
                                <Form.Item
                                    label="被保险人风险状况系数"
                                    name="insuredRiskFactor"
                                >
                                    <Select
                                        placeholder="请选择被保险人风险状况系数"
                                    >
                                        {ratioConfig.map( option => (
                                            <Option key={option.value} value={option.value}>{option.label}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="销售区域系数"
                                    name="saleArea"
                                >
                                    <Select
                                        placeholder="请选择销售区域系数"
                                    >
                                        {ratioConfig.map( option => (
                                            <Option key={option.value} value={option.value}>{option.label}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="风险管理水平系数"
                                    name="riskManage"
                                >
                                    <Select
                                        placeholder="请选择风险管理水平系数"
                                    >
                                        {ratioConfig.map( option => (
                                            <Option key={option.value} value={option.value}>{option.label}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="政府合作或行业性业务系数"
                                    name="manageServiceFactor"
                                >
                                    <Select
                                        placeholder="请选择政府合作或行业性业务系数"
                                    >
                                        {ratioConfig.map( option => (
                                            <Option key={option.value} value={option.value}>{option.label}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="被保险人数或渠道预期投保人数系数"
                                    name="insuredCountFactor"
                                >
                                    <Select
                                        placeholder="请选择被保险人数或渠道预期投保人数系数"
                                    >
                                        {ratioConfig.map( option => (
                                            <Option key={option.value} value={option.value}>{option.label}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="公司目标客户优惠系数"
                                    name="discountFactor"
                                >
                                    <Select
                                        placeholder="请选择公司目标客户优惠系数"
                                    >
                                        {ratioConfig.map( option => (
                                            <Option key={option.value} value={option.value}>{option.label}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="渠道系数"
                                    name="saleFactor"
                                >
                                    <Select
                                        placeholder="请选择渠道系数"
                                    >
                                        {ratioConfig.map( option => (
                                            <Option key={option.value} value={option.value}>{option.label}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Form>
                            <div className="save-init" onClick={() => {
                                this.saveInit(1)
                            }}>保存</div>
                        </div>
                    )}
                    {activeIndex === 4 && (
                        <div className="calculation calculation-show">
                            <Form
                                {...layout}
                                ref={this.profitRef}
                                name="calculator"
                                initialValues={initData}
                            >
                                <Form.Item
                                    label="利润率（%）"
                                    name="profitRate"
                                    rules={[{ required: true, message: '利润率必填' }]}
                                >
                                    <InputNumber placeholder={'请输入利润率'} style={{width: '100%'}} min={1} max={100}/>
                                </Form.Item>
                                <Form.Item
                                    label="GOE项目成本（含税）（%）"
                                    name="goeCost"
                                    rules={[{ required: true, message: '利润率必填' }]}
                                >
                                    <InputNumber placeholder={'请输入GOE项目成本'} style={{width: '100%'}} min={1} max={100}/>
                                </Form.Item>
                                <Form.Item
                                    label="系统费用（%）"
                                    name="sysCost"
                                    rules={[{ required: true, message: '系统费用必填' }]}
                                >
                                    <InputNumber placeholder={'请输入系统费用'} style={{width: '100%'}} min={0} max={100}/>
                                </Form.Item>
                                <Form.Item
                                    label="市场费用（%）"
                                    name="marketCost"
                                    rules={[{ required: true, message: '市场费用必填' }]}
                                >
                                    <InputNumber placeholder={'请输入市场费用'} style={{width: '100%'}} min={0} max={100}/>
                                </Form.Item>
                            </Form>
                            <div className="save-init" onClick={() => {
                                this.saveInit(2)
                            }}>保存</div>
                        </div>
                    )}
                </div>
                <Loading show={loading}/>
            </div>
        )
    }
}

export default SetPrice;