import Cookies from 'js-cookie';

// 设置cookie
export function setCookies(key: string, value: string, expires: number = 900) {
    Cookies.set(key, value , {
        expires: new Date(new Date().getTime() + expires * 1000)
    })
}

// 获取cookie
export function getCookies(key: string) {
    return Cookies.get(key)
}

// 删除cookie
export function removeCookies(key: string) {
    return Cookies.remove(key)
}
