/** @format */

import React, { useState, useEffect } from 'react';
import { Input, Row, Button } from 'antd';
import { sendMessage, checkMobileValid } from '@api/login';
import './index.scss';
import { UserOutlined, LockFilled } from '@ant-design/icons';
interface IProps {
  closeLogin: () => void;
  mobile: string;
}
function Login(props: IProps) {
  const [code, setCode] = useState('');
  const [codeText, setCodeText] = useState('获取验证码');
  const [isSend, setIsSend] = useState(false);
  const [time, setTime] = useState(60);
  useEffect(() => {
    let timer: any = null;
    if (isSend && time !== 0) {
      timer = setInterval(() => {
        // 这时候的num由于闭包的原因，一直是0，所以这里不能用setNum(num-1)
        setTime((n) => {
          if (n === 0) {
            setCodeText('重新获取');
            setTime(60);
            setIsSend(false);

            timer && clearInterval(timer);
          } else {
            setCodeText(`${n}s`);
          }
          return n - 1;
        });
      }, 1000);
    }
    return () => {
      // 组件销毁时，清除定时器
      clearInterval(timer);
    };
  }, [isSend, time]);
  const confirm = () => {
    const { mobile } = props;
    if (mobile && code) {
      checkMobileValid({
        mobile,
        validCode: code,
      })
        .then((res: any) => {
          props.closeLogin();
        })
        .catch(() => {});
    }
  };
  return (
    <div className='login-modal'>
      <div className='login-content'>
        <Row>
          <Input
            className='input mobile'
            placeholder='请输入账号'
            prefix={<UserOutlined style={{ color: '#ccc' }} />}
            value={props.mobile}
            disabled
          />
          <Button
            className={`message ${!props.mobile||isSend ? 'disable' : ''}`}
            disabled={!props.mobile}
            onClick={() => {
              if (isSend) {
                return;
              }
              sendMessage({
                mobile: props.mobile,
              })
                .then(() => {
                  setIsSend(true);
                })
                .catch(() => {});
            }}
          >
            {codeText}
          </Button>
        </Row>
        <Input
          className='input password'
          placeholder='请输入验证码'
          prefix={<LockFilled style={{ color: '#ccc' }} />}
          onChange={(e: any) => {
            setCode(e.target.value);
          }}
        />
        <Button
          className={`login ${props.mobile&&code ? 'active' : ''}`}
          disabled={!props.mobile||!code}
          onClick={() => {
            confirm();
          }}
        >
          确认
        </Button>
      </div>
    </div>
  );
}
export default Login;
