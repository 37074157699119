/** @format */

import React from 'react';
import './index.scss';
import { RouteComponentProps } from 'react-router-dom';
import { Select, DatePicker, Form, Button } from 'antd';
import {
  getSettlementMonth,
  getAgencyList,
  getInsuranceCompanyList,
} from '@api/settlementExport';
const baseUrl = require('@utils/baseUrl.js');
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
interface IState {
  active: number;
  btns: Array<any>;
  dateList: Array<any>;
  supplierList: Array<any>;
  institutionsList: Array<any>;
  companyId: string; //保险公司id
  contractorName: string; //承保机构名称
  settlementMonth: string; //账期
  settlementStatus: string; //状态
  startTime: number;
  endTime: number;
  status: string;
}

interface IProps {
  match?: any;
}

type PropsType = RouteComponentProps & IProps;

class InsuranceCompany extends React.Component<PropsType, IState> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      active: 0,
      btns: [
        { value: 0, name: '保司结算数据', active: true },
        { value: 1, name: '立案数据', active: false },
      ],
      dateList: [],
      supplierList: [], //保险公司
      institutionsList: [], //承保机构
      companyId: '', //保险公司id
      contractorName: '', //承保机构名称
      settlementMonth: '', //账期
      settlementStatus: '', //状态
      startTime: 0,
      endTime: 0,
      status: '0',
    };
  }

  componentDidMount() {
    getSettlementMonth().then((res: any) => {
      this.setState({
        dateList: res,
      });
    });
    getInsuranceCompanyList().then((res: any) => {
      this.setState({
        supplierList: res,
      });
    });
  }
  setQuery(queryConfig: any) {
    let str = '';
    for (var o in queryConfig) {
      if (queryConfig[o]) {
        str += o + '=' + queryConfig[o] + '&';
      }
    }
    str = str.substring(0, str.length - 1); //末尾是&
    return str;
  }
  timeChange(val: any) {
    this.setState({
      startTime: val[0].format('YYYY-MM-DD'),
      endTime: val[1].format('YYYY-MM-DD'),
    });
  }
  btnsChange(index: any) {
    const { btns } = this.state;
    btns.forEach((item) => {
      if (item.active) {
        item.active = false;
      }
    });
    btns[index].active = true;
    this.setState({ btns, active: index });
  }
  handleChange1(val: any) {
    this.setState({
      settlementMonth: val,
    });
  }
  handleChange2(val: any) {
    this.setState({
      settlementStatus: val,
    });
  }
  //会根据保险公司获取承保机构
  handleChange3(val: any) {
    getAgencyList({ companyId: val }).then((res: any) => {
      this.setState({
        companyId: val,
        institutionsList: res,
      });
    });
  }
  handleChange4(val: any) {
    this.setState({
      contractorName: val,
    });
  }
  handleChange5(val: any) {
    this.setState({
      status: val,
    });
  }
  onFinish = (values: any) => {
    const { companyId, contractorName, settlementMonth, settlementStatus } =
      this.state;
    console.log('this.state', this.state);
    let query = {
      companyId,
      contractorName,
      settlementMonth,
      settlementStatus,
    };
    let url =
      baseUrl +
      '/insurance/company-settlement/bill-item/export?' +
      this.setQuery(query);
    console.log('url', encodeURI(url));
    window.location.href = encodeURI(url);
  };
  onFinish1 = (values: any) => {
    const { companyId, contractorName, startTime, endTime, status } =
      this.state;
    let query = {
      insuranceCompanyId: companyId,
      organization: contractorName,
      startTime,
      endTime,
      status,
    };
    let url = baseUrl + '/claim/claim/export?' + this.setQuery(query);
    console.log('url', encodeURI(url));
    window.location.href = encodeURI(url);
  };
  render() {
    const { btns, active, dateList, supplierList, institutionsList } =
      this.state;
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    return (
      <div className='exportData'>
        <div className='container'>
          <div className='tabs'>
            {btns.map((item, index) => {
              return (
                <div
                  key={item.name}
                  onClick={this.btnsChange.bind(this, index)}
                  className={`item ${item.active ? 'active' : ''}`}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          {active === 0 ? (
            <div className='export-main'>
              <Form
                onFinish={this.onFinish.bind(this)}
                {...layout}
                initialValues={{
                  settlementMonth: '',
                  settlementStatus: '',
                  companyId: '',
                  contractorName: '',
                }}
              >
                <div className='export-main-item'>
                  <div className='export-main-item-r'>
                    <Form.Item
                      label='账期'
                      rules={[{ required: true }]}
                      name='settlementMonth'
                    >
                      <Select
                        style={{ width: 320 }}
                        onChange={this.handleChange1.bind(this)}
                      >
                        <Option value=''>全部</Option>
                        {dateList.map((item) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='export-main-item'>
                  <div className='export-main-item-r'>
                    <Form.Item
                      label='结算状态'
                      rules={[{ required: false }]}
                      name='settlementStatus'
                    >
                      <Select
                        style={{ width: 320 }}
                        onChange={this.handleChange2.bind(this)}
                      >
                        <Option value=''>全部</Option>
                        <Option value='0'>待处理</Option>
                        <Option value='1'>已完成</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='export-main-item'>
                  <div className='export-main-item-r'>
                    <Form.Item
                      label='保险公司'
                      rules={[{ required: true }]}
                      name='companyId'
                    >
                      <Select
                        style={{ width: 320 }}
                        onChange={this.handleChange3.bind(this)}
                      >
                        <Option value=''>全部</Option>
                        {supplierList.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.companyName}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='export-main-item'>
                  <div className='export-main-item-r'>
                    <Form.Item
                      label='承保机构'
                      rules={[{ required: false }]}
                      name='contractorName'
                    >
                      <Select
                        style={{ width: 320 }}
                        onChange={this.handleChange4.bind(this)}
                      >
                        <Option value=''>全部</Option>
                        {institutionsList.map((item) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='activeBtn'>
                  <Form.Item>
                    <Button type='primary' size='large' htmlType='submit'>
                      批量导出
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          ) : null}
          {active === 1 ? (
            <div className='export-main'>
              <Form
                onFinish={this.onFinish1.bind(this)}
                {...layout}
                initialValues={{
                  status: '0',
                  companyId: '',
                  contractorName: '',
                }}
              >
                <div className='export-main-item'>
                  <div className='export-main-item-r'>
                    <Form.Item
                      label='申请时间'
                      rules={[{ required: true }]}
                      name='time'
                    >
                      <RangePicker
                        onChange={this.timeChange.bind(this)}
                        style={{ width: 320 }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='export-main-item'>
                  <div className='export-main-item-r'>
                    <Form.Item
                      label='审核状态'
                      rules={[{ required: false }]}
                      name='status'
                    >
                      <Select
                        style={{ width: 320 }}
                        onChange={this.handleChange5.bind(this)}
                      >
                        <Option value='0'>全部</Option>
                        <Option value='1'>待审核</Option>
                        <Option value='2'>审核通过</Option>
                        <Option value='3'>审核失败</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='export-main-item'>
                  <div className='export-main-item-r'>
                    <Form.Item
                      label='保险公司'
                      rules={[{ required: true }]}
                      name='companyId'
                    >
                      <Select
                        style={{ width: 320 }}
                        onChange={this.handleChange3.bind(this)}
                      >
                        <Option value=''>全部</Option>
                        {supplierList.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.companyName}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='export-main-item'>
                  <div className='export-main-item-r'>
                    <Form.Item
                      label='承保机构'
                      rules={[{ required: false }]}
                      name='contractorName'
                    >
                      <Select
                        style={{ width: 320 }}
                        onChange={this.handleChange4.bind(this)}
                      >
                        <Option value=''>全部</Option>
                        {institutionsList.map((item) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='activeBtn'>
                  <Form.Item>
                    <Button type='primary' size='large' htmlType='submit'>
                      批量导出
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default InsuranceCompany;
