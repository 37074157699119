/** @format */

import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { getCookies } from '@utils/cookie';
import { message } from 'antd';
var service: any = axios.create({
  // URL
  baseURL: '',
  // 请求超时时间
  timeout: 30000,
});

// 请求拦截器
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // token存在,请求是添加token信息
    if (getCookies('K-Access-Token')) {
      config.headers['K-Access-Token'] = getCookies('K-Access-Token');
      config.headers['K-Client'] = `id:${getCookies('K-Client')}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

// 返回拦截器
service.interceptors.response.use(
  (response: AxiosResponse) => {
    const res = response.data;
    if (!res.status) {
      message.error(res.message);
      return Promise.reject(res.message || 'Error');
    } else {
      return res.data;
    }
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

export default service;
