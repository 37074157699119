import React from 'react';
import './index.scss';

const Loading = (props: {show: boolean}) => {
    let { show = false } = props;
    return (
        <React.Fragment>
            {show && (
                <div className="loading">
                    <div className="gif-box">
                        <div className="gif"></div>
                    </div>
                </div>
            )}
        </React.Fragment>
        
    )
}

export default Loading;