import { action, observable } from 'mobx';
// import { LoginFrom } from './interface';
import * as setPriceApi from '@api/serPrice';

interface insuranceProductItem {
    id: string,
    name: string
}

interface FinalPrice {
    integer: string,
    decimal: string
}

class Store {
    @observable insuranceProductList: insuranceProductItem[] = [];
    @observable serviceItemsList: any[] = [];
    @observable finalPrice:FinalPrice = {
        integer: '0',
        decimal: '.00'
    };
    @observable serviceList: any[] = [];
    @observable initData: any = {};

    // 获取保险产品
    @action
    insuranceProduct = () => {
        setPriceApi.insuranceProduct().then( (res:any) => {
            this.insuranceProductList = res || [];
        })
    }

    // 获取保险产品服务项
    @action
    getServiceItems = async (id:string) => {
        await setPriceApi.getServiceItems({
            id
        }).then( (res:any) => {
            this.serviceItemsList = res || [];
        })
    }

    // 测算保费
    @action
    setPrice = async (postData:any) => {
        await setPriceApi.setPrice(postData).then((res: any) => {
            setTimeout(() => {
                // 加1分防止四舍五入导致成本变低
                let finalPrice:string = String(res.finalPrice + 0.01);
                let index = finalPrice.indexOf('.');
                if(index > -1) {
                    this.finalPrice = {
                        integer: finalPrice.substring(0, index),
                        decimal: finalPrice.substring(index, index + 3)
                    }
                } else {
                    this.finalPrice = {
                        integer: finalPrice,
                        decimal: '.00'
                    }
                }
                
                this.serviceList = res.vos ||  [];
            }, 2000)
        })
    }

    // 获取初始化数据
    @action
    getInitData = () => {
        setPriceApi.getInitData().then( (res:any) => {
            this.initData = res || {};
        })
    }

    // 保存初始化数据
    @action
    saveInitData = (initData: any) => {
        return setPriceApi.saveInitData(initData)
    }
}

export default new Store();