import { observable, action } from 'mobx';

class Store {
    @observable userName:String = '用户名'
    
    @action getId = () => {
        console.log(this.userName)
    }
}

export default new Store()