import axios from 'axios';
import request from '@utils/request';
//结算数据导出
export function exportSettlement(data: any) {
    return axios({
        url: `/api/insurance/company-settlement/bill-item/export`,
        method: 'post',
        data
    })
}
//账期列表
export function getSettlementMonth() {
    return request({
        url: `/api/insurance/company-settlement/settlement-month`,
        method: "get"
    });
}
//保险公司的承保机构
export function getAgencyList(params:any) {
    return request({
        url: `/api/insurance/company-settlement/agency`,
        method: "get",
        params
    });
}
// 保险公司列表查询
export function getInsuranceCompanyList() {
    return request({
        url: '/api/insurance/insuranceCompany/query',
        method: 'get'
    })
}
// 立案数据导出
export function exportClaimData(params:any) {
    return request({
        url: '/api/claim/claim/export',
        method: 'get',
        params
    })
}