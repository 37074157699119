import homeStore from './home';
import loginStore from './login';
import setPriceStore from './setPrice';
import creditStore from './credit';

import { IStore } from './interface';


const Store:IStore = {
    homeStore,
    loginStore,
    creditStore,
    setPriceStore,
}

// 俩种方式实现全局Store的封装
// class Store {
//     homeStore: HomeStore

//     constructor() {
//         this.homeStore = homeStore
//     }
// }
// export default new Store();

export default Store;