import request from '@utils/request';
import { LoginFrom, SendMessage, CheckMobileValid } from '@store/login/interface';

export function login(params: LoginFrom) {
    return request({
        url: '/api/scm/auth/token',
        method: 'post',
        params
    })
}
export function sendMessage(params: SendMessage) {
    return request({
        url: '/api/insurance/underwriting/third/smscode',
        method: 'get',
        params
    })
}
export function checkMobileValid(data: CheckMobileValid) {
    return request({
        url: '/api/insurance/underwriting/check/mobileAndValidCode',
        method: 'post',
        data
    })
}