import { observable, action } from 'mobx';
import { Personal, Company } from './interface'
import * as creditApi from '@api/credit'
import { personalForm, companyForm } from '@store/credit/interface'
class Store {
    @observable personal:Personal = {
        score: 0,
        name: '',
        age: 0,
        country: '',
        birth: '',
        blood: '',
        constellation: '',
        education: '',
        idCard: '',
        gender: '',
        hometown: '',
        mobile: '',
        birthplace: '',
        marriage: '',
        job: '',
        behaviorRecords: []
    }
    @action getPersonalCredit = (params:personalForm) => {
        return creditApi.personalApi(params)
    }
    @action setPersonal = (personal: Personal) => {
        this.personal = personal
    }
    @observable company:Company = {
        name: '',
        content: { 
            totalScore: 0,
            grade: '',
            name: '',
            address: '',
            email: '',
            businessScope: '',
            type: '',
            createTime: '',
            internetAddress: '',
            socialEvaluation: {
                score: 0,
                remark: '',
                highlight: []
            },
            risk: {
                score: 0,
                remark: ''
            },
            dispute: {
                score: 0,
                remark: '',
                highlight: []
            },
            coverage: {
                score: 0,
                remark: '',
                highlight: []
            },
            size: {
                score: 0,
                remark: '',
                highlight: []
            },
            comment: {
                score: 0,
                remark: '',
                highlight: []
            }
        }
    }
    @action getCompanyCredit = (params: companyForm) => {
        return creditApi.comapnyApi(params)
    }
    @action setCompany = (company: Company) => {
        this.company = company
    }
    

    
    
}

export default new Store()