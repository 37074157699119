import React, { useState } from 'react';
import { Modal ,Upload, message, Button, Table } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { submitPersonList, uploadList } from '@api/uploadGroupList';
import moment from 'moment';
import './index.scss';
interface IProps {
    id: string,
    handleCancel: () => void
}

const dataStatus:any = {
    '0': '正确',
    '1': '证件类型错误',
    '2': '数据缺失',
    '3': '身份证号格式错误',
    '4': '性别错误',
    '5': '姓名格式错误',
    '6': '出生日期格式错误',
    '7': '姓名与身份证号码不对应',
    '8': '年龄与出生日期不对应',
    '9': '出生日期与身份证号码不符合',
    '10': '职业不超过10个字',
    '11': '表单中身份证号码重复'
}

const UploadModal = (props: IProps) => {

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [list, setList] = useState([]);

    const handleOk = () => {
        submitPersonList({
            insureRecordId: props.id,
            list
        }).then(() => {
            message.success('上传成功')
            props.handleCancel && props.handleCancel()
        }).catch((error: string) => {
            message.error(error)
        })
    }

    const handleCancel = () => {
        props.handleCancel && props.handleCancel()
    }

    const uploadProps:any = {
        name: 'file',
        showUploadList: false,
        accept: '.xlsx, .xls',
        beforeUpload: (file: any) => {
            const limit10M = file.size / 1024 / 1024 < 10;
            if(!limit10M) {
                message.warning('文件大小超过10M')
                return false
            }
            const formData = new FormData();
            formData.append('file', file);
            formData.append('insureRecordId', props.id);
            setLoading(true);
            uploadList(formData).then((res: any) => {
                setList(res || []);
                let submitStatus = false;
                res.forEach((item: any) => {
                    if(item.dataState) {
                        submitStatus = true
                        
                    }
                })
                setDisabled(submitStatus)
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            })
            return false
        }
    }

    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '证件类型',
            dataIndex: 'cardType',
            key: 'cardType',
        },
        {
            title: '证件号码',
            dataIndex: 'cardCode',
            key: 'cardCode',
            width: 200
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
        },
        {
            title: '出生日期',
            dataIndex: 'birth',
            key: 'birth',
            render: (text: React.ReactNode) => <span>{moment(Number(text)).format('YYYY-MM-DD')}</span>
        },
        {
            title: '年龄',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: '职业',
            dataIndex: 'job',
            key: 'job',
        },
        {
            title: '状态',
            dataIndex: 'dataState',
            key: 'dataState',
            render: (text: any) => (
                !text ? <span className="success">{dataStatus[text]}</span> : <span className="error">{dataStatus[text]}</span>
            )
        }
    ];

    return (
        <Modal
            title="上传名单"
            open={true}
            onOk={handleOk}
            confirmLoading={loading}
            onCancel={handleCancel}
            width="1200px"
            footer={[
                <Button key="back" onClick={handleCancel}>
                    取消
                </Button>,
                <Button key="submit" disabled={disabled} type="primary" loading={loading} onClick={handleOk}>
                    确定
                </Button>,
            ]}
        >
            {list.length>0 ? (
                <div className="group-list">
                    <div className="upload-file">
                        <Upload {...uploadProps}>
                            <Button>
                                <UploadOutlined /> 
                                重新上传
                            </Button>
                        </Upload>
                    </div>
                    <Table scroll={{y: '500px'}} columns={columns} dataSource={list} pagination={false} rowKey="cardCode"/>
                </div>
            ) : (
                <div className="upload-file">
                    <Upload {...uploadProps}>
                        <Button>
                            <UploadOutlined /> 
                            上传名单
                        </Button>
                    </Upload>
                </div>
            )}
        </Modal>
    )
}

export default UploadModal;