import request from '@utils/request';

interface Config {
    url: string;
    method: string;
    data?: any;
    params?: any;
}


// 四舍五入
export function ForDight(Dight: number, How: number){  
    Dight = Math.round(Dight*Math.pow(10, How))/Math.pow(10, How);  
    return String(Dight);  
}  
// 获取路由参数
export const getUrlParams = (name: string, url?: string) => {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&'); // eslint-disable-line
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
        return '';
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// 请求返回处理
export const http = (config: Config) => {
    return new Promise((resolve, rejects) => {
        request(config).then((res: any) => {
            resolve(res)
        }).catch(() => {
            rejects()
        })
    })
}