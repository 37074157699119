interface IRatioConfig {
    label: number,
    value: number
}

export const ratioConfig:IRatioConfig[]  = [{
    label: 0.5,
    value: 0.5
},{
    label: 0.6,
    value: 0.6
},{
    label: 0.7,
    value: 0.7
},{
    label: 0.8,
    value: 0.8
},{
    label: 0.9,
    value: 0.9
},{
    label: 1.0,
    value: 1.0
},{
    label: 1.1,
    value: 1.1
},{
    label: 1.2,
    value: 1.2
},{
    label: 1.3,
    value: 1.3
},{
    label: 1.4,
    value: 1.4
},{
    label: 1.5,
    value: 1.5
}]