import React from 'react';
import { Route, Switch, Redirect} from 'react-router-dom';

const PersonalCredit = React.lazy(() => import(/* webpackChunkName: "personal" */ './personal'));
const CompanyCredit = React.lazy(() => import(/* webpackChunkName: "company" */ './company'));



export default class CcicRoute extends React.Component {
  render() {
    return (
      <Switch>
        <Redirect exact path="/credit" to="/credit/personal" />
        <Route path="/credit/personal/:idCardEnd" component={PersonalCredit} />
        <Route path="/credit/company/:id" component={CompanyCredit} />
      </Switch>
    );
  }
}
