import request from '@utils/request';

export function insuranceProduct() {
    return request({
        url: '/api/scm/insuranceProduct/prodList',
        method: 'get'
    })
}

export function getServiceItems(params: {id: string}) {
    return request({
        url: '/api/scm/insuranceProduct/detailList',
        method: 'get',
        params
    })
}

export function setPrice (data: any) {
    return request({
        url: '/api/scm/price/setPrice',
        method: 'post',
        data
    })
}

export function getInitData () {
    return request({
        url: '/api/scm/price/init',
        method: 'get',
    })
}

export function saveInitData (data: any) {
    return request({
        url: '/api/scm/price/saveInit',
        method: 'post',
        data
    })
}