import request from '@utils/request';
import { personalForm, companyForm } from '@store/credit/interface'
export function personalApi(params:personalForm) {
    return request({
        url: '/api/scm/person/credit/idCardEnd',
        method: 'get',
        params
    })
}
export function comapnyApi(params: companyForm) {
    return request({
        url: '/api/scm/company/credit/id',
        method: 'get',
        params
    })
}